import React from "react";
import "./footer.css";

function Footer() {
  return (
    <>
      <div className="footer-wrapper">
        <footer className="footer"></footer>
      </div>
    </>
  );
}

export default Footer;
