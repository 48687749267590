import React from "react";
import "./Home.css";
import parma from "../../assets/parma.png";
import lasagna from "../../assets/lasagna.png";
import Risotto from "../../assets/risotto.png";
import Map from "../../component/Map";
import Map1 from "../../component/Map1";

function Home() {
  return (
    <>
      <div className="home-container">
        <h1>
          Amor Di Pasta is a family owned and operated Italian Restaurant <br />
          By Italian owners located in Wynwood
        </h1>
        <section className="images">
          <img src={parma} alt="parma burata pizza" className="im" />
          <img src={Risotto} alt="risottos" className="im" />
          <img src={lasagna} alt="lasagna alla bolognese" className="im" />
        </section>
        <section className="hours">
          <h2>Sunday to Thursday | 12:00 to 10:00pm</h2>
          <h2>Friday to Saturday | 12:00 to 11:00pm</h2>
        </section>
        <section className="map">
          <div className="location-1">
            <h3>Amor Di Pasta, Edgewater</h3>
            <div className="map-details">
              <Map />
            </div>
            <h3>2320 NE 2nd Ave, Miami, FL 33137</h3>
            <a
              href="https://www.ubereats.com/store/amor-di-pasta-edgewater/Q6rT3JnoTJm_ssCPi_-wUA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="online-order">Order Online</button>
            </a>
          </div>
          <div className="location-2">
            <h3>Amor Di Pasta Doral at Shoma Bazaar</h3>
            <div className="map-details">
              <Map1 />
            </div>
            <h3>9420 NW 41st st, Doral, FL 33178</h3>
            <a
              href="https://www.order.store/store/amor-di-pasta-doral/ukRruOikXXKQGt8BcDsCew?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMjkyNDAlMjBGb250YWluZWJsZWF1JTIwQmx2ZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMjEyOWVhNzI3LTYxMzMtOTM3Ny0xNmUyLWQ5OWYyY2ZiNWRlMyUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJ1YmVyX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBMjUuNzcxMjIyNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS04MC4zNDU0NjM3JTdE"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="online-order">Order Online</button>
            </a>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
